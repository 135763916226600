import * as React from "react"
import JSONData from "../data/texts.json"
import AppLayout from "../components/layouts/app"

const Invitation = () => {
  const page = {
    title: "Invitation to Remoco",
    description: ""
  }

  const user = {
    name: "Peter"
  }
  return (
    <main >
      <title>Invitation to Remoco</title>
      <AppLayout menu={JSONData.menu} page={page}>
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div className="card card-primary invitation valid">
              <div className="card-header">
                <h4>Welcome to Remoco!</h4>
              </div>
              <div className="card-body">
                <form method="" id="invitation-form" name="invitation">
                  <p className="text-muted">
                    You've accepted <strong>{user.name}'s</strong> invitation to join Remoco
                    <br />
                    <div className="form-group">
                      <label htmlFor="user-email">Email</label>
                      <input type="email" className="form-control" id="user-email" required="" disabled/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="user-pwd">Password</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <i className="fas fa-lock"></i>
                          </div>
                        </div>
                        <input type="password" className="form-control pwstrength" data-indicator="pwindicator" id="user-pwd"/>
                      </div>
                      <div id="pwindicator" className="pwindicator">
                        <div className="bar"></div>
                        <div className="label"></div>
                      </div>
                    </div>
                    By continuing, you agree to the <a href="/terms-of-service">Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a>.
                    <div className="text-right">
                      <button className="btn btn-primary mr-1" type="submit" aria-label="Join">Join</button>
                    </div>
                  </p>
                </form>
              </div>
            </div>
            <div className="card card-primary invitation invalid">
              <div className="card-header">
                <h4>Invitation is out of date</h4>
              </div>
              <div className="card-body">
                <p className="text-muted">
                  It seems that the invitation link is outdated. Ask the person who invited you to resend you the link.
                </p>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </main>
  )
}

export default Invitation